<template>
  <v-tab
    :id="tab.id"
    :label="tab.name"
  >
    <h1 id="anchor_1" class="text-color-text-secondary mb-24">
      Рекомендации к проведению интервью по компетенциям
    </h1>

    <h2 id="anchor_1-1" class="mb-24">
      1. Вступление к интервью по компетенциям
    </h2>

    <p>Прежде чем вы перейдете к вопросам по компетенциям, произнесите вступительное слово:</p>

    <p>
      «Сейчас нам предстоит интервью по компетенциям, которое займет около 30-40 минут. Я буду задавать вопросы,
      связанные с Вашей рабочей деятельности. Попрошу отвечать на них максимально четко, поскольку времени у нас
      немного. Заранее приношу извинения, если буду Вас перебивать. Я буду просить Вас приводить примеры
      конкретных рабочих ситуаций и описывать свои действия. Прошу Вас рассказывать именно о своих действиях,
      даже
      если в обсуждаемую ситуацию были вовлечены другие люди
    </p>

    <p>Есть ряд <b>требований к примерам ситуаций</b>. Ситуация должна быть:</p>

    <ul>
      <li>Актуальной (произошла за последний год, максимум два года назад);</li>
      <li>С Вашим активным личным участием;</li>
      <li>Завершенной (т.е. завершилась к настоящему моменту);</li>
    </ul>

    <p>
      При этом результат ситуации может быть как позитивным, так и негативным (часто в ситуациях, которые не
      развиваются гладко, когда что-то не получается, мы проявляем свои самые сильные качества).
    </p>

    <p>
      На протяжении всего интервью я буду вести записи, они нужны для дальнейшей корректной интерпретации Ваших
      ответов, постарайтесь на это не отвлекаться. Есть ли у Вас вопросы сейчас? В конце интервью у Вас также
      будет возможность задать мне вопросы».
    </p>

    <h2
      id="anchor_1-2"
      class="mb-24"
    >
      2. Основная часть
    </h2>

    <p>Последовательно задавайте вопросы по компетенциям, фиксируйте ответы кандидата.</p>

    <p>Следует придерживаться следующих принципов в оценке:</p>

    <ul>
      <li>Наблюдать (слушать 80% времени, а говорить – 20% времени, применять активное слушание);</li>
      <li>
        Описывать (записывать все, что Вы видите и слышите от кандидата, отмечать яркие невербальные
        проявления,
        затем – краткое резюме действий кандидата. Не записывать оценочные суждения, они мешают объективно
        оценить
        поведение кандидата);
      </li>
      <li>Классифицировать (соотносить примеры поведения с конкретными компетенциями);</li>
      <li>Оценивать (сила (яркость) проявления того или иного поведения должна быть принята в расчет).</li>
    </ul>

    <h2
      id="anchor_1-3"
      class="mb-24"
    >
      3. Завершение интервью
    </h2>

    <p>
      По завершении интервью поблагодарите участника за ответы на вопросы и обсудите дальнейшие действия (когда
      будет предоставлена обратная связь, что будет происходить с кандидатом дальше).
    </p>

    <h1
      id="anchor_2"
      class="text-color-text-secondary mb-24"
    >
      Рекомендации к заполнению оценочной формы
    </h1>

    <ol>
      <li>
        По окончании интервью оцените ответы кандидата, используя предложенную шкалу в оценочной форме. Для
        этого в каждом индикаторе компетенции выберите тот вариант оценки, который соответствует поведению и
        ответам кандидата.
      </li>
      <li>
        Итоговая оценка по компетенции будет рассчитана системой автоматически на основе Ваших оценок по
        индикаторам.
      </li>
    </ol>
  </v-tab>
</template>

<script>
import { VTab } from '@components/base'

export default {
  name: 'SNlmkCompetencyAssessmentTab1',

  components: { VTab },

  props: {
    tab: Object
  }
}
</script>
