<template>
  <l-content-widget
    :sticky="widget.isSticky"
    fullHeight
  >
    <template #widget>
      <l-widget
        fixed-height
        no-ident
      >
        <template #header>
          <div class="nlmk-ca-widget-header">
            <b>Константинский К.</b>
          </div>
        </template>

        <template #default>
          <div class="nlmk-ca-widget-main">
            <ul class="ul d-none d-xl-block">
              <li
                class="pb-16"
                v-for="(item, i) in currentTab.anchors"
                :key="i"
              >
                <a
                  class="link-standard"
                  :href="`#${item.link}`"
                >
                  {{ item.title }}
                </a>

                <ol
                  v-if="item.sublist && item.sublist.length > 0"
                  class="ol pl-32 pt-16"
                >
                  <li
                    class="pb-8"
                    v-for="(subitem, j) in item.sublist"
                    :key="j"
                  >
                    <a
                      class="link-standard"
                      :href="`#${subitem.link}`"
                    >
                      {{ subitem.title }}
                    </a>
                  </li>
                </ol>
              </li>
            </ul>

            <v-select
              :value="widget.selectedAnchor"
              :options="currentTab.anchors"
              :clearable="false"
              :filterable="false"
              :searchable="false"
              label="title"
              @input="changeSelect"
              class="d-xl-none v-select_type_widget"
            />
          </div>
        </template>

        <template
          v-if="currentId === '3'"
          #footer
        >
          <div class="nlmk-ca-widget-prefooter">
            <b>Алгоритм сбора информации:</b>

            <ul class="ul mt-8">
              <li><span class="text-color-primary">S</span>ituation — Пример ситуации</li>
              <li><span class="text-color-primary">T</span>ask — Какая стояла задача</li>
              <li><span class="text-color-primary">A</span>ction — Что предпринимал</li>
              <li><span class="text-color-primary">R</span>esults — Какой результат</li>
            </ul>
          </div>
          <div
            class="nlmk-ca-widget-footer"
            @click="widget.showModal = !widget.showModal"
          >
            Завершить оценку
          </div>
        </template>
      </l-widget>
    </template>

    <template #default>
      <v-tabs
        position="right"
        @change="setCurrentId"
      >
        <template
          v-for="(tab, index) in tabs"
          v-slot:[`slot-${index}`]="{ isActive }"
        >
          <div
            :key="index"
            class="d-flex align-items-center justify-content-center"
          >
            <v-icon-svg
              :class="`v-icon-svg_mode_${index}`"
              view-box="0 0 24 24"
              width="24"
              height="24"
            >
              <component :is="`SvgIconNlmk${tab.icon}`"/>
            </v-icon-svg>

            <span class="ml-12" v-if="isActive">
              {{ tab.name }}
            </span>
          </div>
        </template>

        <component
          v-for="(tab, index) in tabs"
          :key="index"
          :is="`SNlmkCompetencyAssessmentTab${tab.id}`"
          :tab="tab"
          class="px-32 py-16 "
        />
      </v-tabs>

      <v-modal
        v-model="widget.showModal"
      >
        <h4 slot="header">Внимание!</h4>

        Вы уверены, что хотите завершить оценку?
        Вы не сможете больше изменить оценку.

        <div class="row mt-40">
          <div class="col-auto">
            <v-button
              @click="widget.showModal = false"
            >
              Завершить оценку
            </v-button>
          </div>
          <div class="col-auto">
            <v-button
              color="default"
              outlined
              @click="widget.showModal = false"
            >
              Отмена
            </v-button>
          </div>
        </div>
      </v-modal>
    </template>
  </l-content-widget>
</template>

<script>
import VSelect from 'vue-select'
import { LContentWidget, LWidget } from '@/layouts'
import {
  VCard,
  VIconSvg,
  VTab,
  VTabs,
  VAvatar
} from '@components/base'
import SvgIconNlmkInfo from '@components/icons/NLMK/SvgIconNlmkInfo'
import SvgIconNlmkAssessment from '@components/icons/NLMK/SvgIconNlmkAssessment'
import SvgIconNlmkPrewAssessment from '@components/icons/NLMK/SvgIconNlmkPrewAssessment'
import SNlmkCompetencyAssessmentTab1 from './SNlmkCompetencyAssessmentTab1'
import SNlmkCompetencyAssessmentTab2 from './SNlmkCompetencyAssessmentTab2'
import SNlmkCompetencyAssessmentTab3 from './SNlmkCompetencyAssessmentTab3'
import VModal from '@components/base/VModal/VModal'
import VButton from '@components/base/VButton/VButton'

export default {
  name: 'SNlmkСompetencyAssessment',

  components: {
    VButton,
    VModal,
    LContentWidget,
    LWidget,
    SNlmkCompetencyAssessmentTab1,
    SNlmkCompetencyAssessmentTab2,
    SNlmkCompetencyAssessmentTab3,
    SvgIconNlmkAssessment,
    SvgIconNlmkInfo,
    SvgIconNlmkPrewAssessment,
    VAvatar,
    VCard,
    VIconSvg,
    VSelect,
    VTab,
    VTabs
  },

  data () {
    return {
      widget: {
        isSticky: window.innerWidth > 768,
        selectedAnchor: null,
        showModal: false
      },
      currentId: '1',
      tabs: [
        {
          id: '1',
          name: 'Общая информация',
          icon: 'Info',
          anchors: [
            {
              link: 'anchor_1',
              title: 'Рекомендации к проведению интервью',
              sublist: [
                { link: 'anchor_1-1', title: 'Вступление к интервью по компетенциям' },
                { link: 'anchor_1-2', title: 'Основная часть' },
                { link: 'anchor_1-3', title: 'Завершение интервью' }
              ]
            },
            {
              link: 'anchor_2',
              title: 'Рекомендации к заполнению оценочной формы',
              sublist: null
            }
          ]
        },
        {
          id: '2',
          name: 'Предыдущие оценки',
          icon: 'PrewAssessment',
          anchors: [
            {
              link: 'anchor_2_continuous-improvements',
              title: 'Непрерывные улучшения',
              sublist: null
            },
            {
              link: 'anchor_2_systems-thinking',
              title: 'Системное мышление',
              sublist: null
            }
          ]
        },
        {
          id: '3',
          name: 'Оценка',
          icon: 'Assessment',
          anchors: [
            {
              link: 'anchor_3_cooperation',
              title: 'Сотрудничество',
              sublist: null
            },
            {
              link: 'anchor_3_efficiency',
              title: 'Результативность',
              sublist: null
            }
          ]
        }
      ]
    }
  },

  computed: {
    currentTab () {
      return this.tabs.find(tab => tab.id === this.currentId)
    }
  },

  methods: {
    setCurrentId (id) {
      this.widget.selectedAnchor = this.tabs.find(tab => tab.id === id).anchors[0]
      this.currentId = id
    },
    changeSelect (selectedAnchor) {
      this.widget.selectedAnchor = selectedAnchor

      this.$nextTick(() => {
        window.location.hash = `#${selectedAnchor.link}`
      })
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";
@import "~bootstrap/scss/mixins/breakpoints";

.v-icon-svg {
  &_mode {
    &_0 {
      top: 0
    }

    &_1 {
      top: 0
    }

    &_2 {
      top: 0
    }
  }
}

.nlmk-ca-widget-header,
.nlmk-ca-widget-main,
.nlmk-ca-widget-prefooter,
.nlmk-ca-widget-footer {
  padding: 1rem 1.5rem;

  @include media-breakpoint-down(lg) {
    padding: .75rem 1rem;
  }

  @include media-breakpoint-down(sm) {
    padding: .5rem 1rem;
  }
}

.nlmk-ca-widget-main {
  @include media-breakpoint-down(lg) {
    height: 100%;
    padding: 0;
  }
}

.nlmk-ca-widget-prefooter {
  font-size: .875rem;

  @include media-breakpoint-down(lg) {
    display: none
  }
}

.nlmk-ca-widget-footer {
  font-size: .875rem;
  font-weight: 600;
  border-radius: 0 0 br(lg) br(lg);
  cursor: pointer;
  height: 100%;

  @include media-breakpoint-up(md) {
    text-align: center;
    color: white;
    background-color: cl(primary);
  }

  @include media-breakpoint-down(lg) {
    text-align: right;
    border-radius: 0 br(lg) br(lg) 0;
  }

}
</style>
