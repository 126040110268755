<template>
  <v-tab
    :id="tab.id"
    :label="tab.name"
  >
    <div class="content-fluid">
      <div
        v-for="(anchor, j) in tab.anchors"
        :id="anchor.link"
        :key="j"
        class="row"
      >
        <div class="col">
          <h3 class="text-color-primary mb-16">
            {{ anchor.title }}
          </h3>

          <v-sheet
            rounded
            outlined
            class="text-size-12 mb-16 bg-color-background-7 p-12"
          >
            <b>Вводные вопросы к компетенции</b>
            <p class="mb-0">
              Каким образом Вы обменивались информацией?
              Какова была Ваша роль? Что Вы делали?
              К какому результату пришли в этой ситуации?
            </p>
          </v-sheet>

          <div class="mb-40">
            <s-nlmk-competency-assessment-tab3-table
              v-for="i of 3"
              :key="i"
              class="mb-16"
            />
          </div>

          <div class="mt-40">
            <p class="text-right"><b>Итоговая оценка: 4</b></p>

            <v-field class="mt-24 mb-40">
              <label>Комментарий</label>
              <v-textarea
                placeholder="Напишите комментарий"
                autogrow
              />
            </v-field>
          </div>
        </div>
      </div>
    </div>
  </v-tab>
</template>

<script>
import {
  VField,
  VTab,
  VTextarea,
  VSheet
} from '@components/base'
import SNlmkCompetencyAssessmentTab3Table
  from '@views/Guide/Screen/SNlmkCompetencyAssessment/SNlmkCompetencyAssessmentTab3Table'

export default {
  name: 'SNlmkCompetencyAssessmentTab3',

  components: {
    VSheet,
    SNlmkCompetencyAssessmentTab3Table,
    VField,
    VTab,
    VTextarea
  },

  props: {
    tab: Object
  }
}
</script>
