<template>
  <v-table class="text-size-12">
    <template #thead>
      <v-table-tr
          class="cursor-pointer"
          @click="isOpen = !isOpen"
      >
        <v-table-th colspan="2" class="bg-color-primary">
          <div class="row text-color-background-3">
            <div class="col text-left">
              Выявляет зоны неэффективности в собственной работе
            </div>

            <div class="col-auto">
              <v-icon-svg
                  class="a-flip-y svg-stroke-background-3"
                  :class="{'a-flip-y_active': isOpen}"
                  fill="none"
                  view-box="0 0 11 5"
                  title="svg-icon-chevron"
                  width="12"
                  height="12"
              >
                <svg-icon-chevron/>
              </v-icon-svg>
            </div>
          </div>
        </v-table-th>
      </v-table-tr>
    </template>

    <template
        v-if="isOpen"
        #default
    >
      <v-table-tr style="opacity: .5">
        <v-table-td>
          В дополнение к «
          <v-avatar color="success" size="16">+</v-avatar>
          »: Целенаправленно выявляет недостатки в процессах всего подразделения.
        </v-table-td>

        <v-table-td class="text-center text-size-16 text-bold" style="min-width: 6rem">
          <v-avatar color="success" size="16" class="mr-4">+</v-avatar>
          <v-avatar color="success" size="16">+</v-avatar>
        </v-table-td>
      </v-table-tr>

      <v-table-tr class="bg-color-background-2">
        <v-table-td>
          Находит недостатки в подконтрольных ему процессах.
        </v-table-td>

        <v-table-td class="text-center text-size-16 text-bold" style="min-width: 6rem">
          <v-avatar color="success" size="16">+</v-avatar>
        </v-table-td>
      </v-table-tr>

      <v-table-tr style="opacity: .5">
        <v-table-td>
          Не в полном объеме проявляет поведение, соответствующее уровню «
          <v-avatar color="success" size="16">+</v-avatar>
          ».
        </v-table-td>

        <v-table-td class="text-center text-size-16 text-bold" style="min-width: 6rem">
          <v-avatar color="success" size="16" class="mr-4">+</v-avatar>
          <v-avatar color="danger" size="16">−</v-avatar>
        </v-table-td>
      </v-table-tr>

      <v-table-tr style="opacity: .5">
        <v-table-td>
          Стремится сохранить "статус кво", говорит, что текущие процессы эффективны и не нуждаются в трансформации,
          противится внедрению мероприятий по повышению эффективности, которые спускаются сверху.
        </v-table-td>

        <v-table-td class="text-center text-size-16 text-bold" style="min-width: 6rem">
          <v-avatar color="danger" size="16">−</v-avatar>
        </v-table-td>
      </v-table-tr>

      <v-table-tr style="opacity: .5">
        <v-table-td>
          Недостаточно данных для оценки
        </v-table-td>

        <v-table-td class="text-center text-size-16 text-bold" style="min-width: 6rem">
          <v-avatar color="default" size="16" class="mr-4">
            <b class="text-size-8">N</b>
          </v-avatar>
        </v-table-td>
      </v-table-tr>
    </template>
  </v-table>
</template>

<script>
import {
  VAvatar,
  VIconSvg,
  VTable,
  VTableTd,
  VTableTh,
  VTableTr
} from '@components/base'
import SvgIconChevron from '@components/icons/SvgIconChevron'

export default {
  name: 'ProcessingProcess51Tab2Table',

  components: {
    SvgIconChevron,
    VAvatar,
    VIconSvg,
    VTable,
    VTableTd,
    VTableTh,
    VTableTr
  },

  props: {
    content: Array
  },

  data () {
    return {
      isOpen: true
    }
  }
}
</script>
