<template>
  <v-tab
    :id="tab.id"
    :label="tab.name"
  >
    <v-expansion-panels open-at-start  flat>
      <template v-for="(anchor, j) in tab.anchors">
        <div :key="j">

        <hr v-if="j !== 0" class="my-24"/>

        <v-expansion-panel>
          <v-expansion-panel-header no-icon>
            <template v-slot:default="{ isOpen }">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <h3
                      :id="anchor.link"
                      class="text-color-primary"
                    >
                      {{ anchor.title }}
                    </h3>
                  </div>

                  <div class="col-auto">
                    <v-icon-svg
                      class="a-flip-y svg-stroke-primary"
                      :class="{'a-flip-y_active': isOpen}"
                      fill="none"
                      view-box="0 0 11 5"
                      title="svg-icon-chevron"
                      width="12"
                      height="12"
                    >
                      <svg-icon-chevron/>
                    </v-icon-svg>
                  </div>
                </div>

                <div class="row mt-16">
                  <div class="col">
                    Оценивающий: Иванов Иван Иванович
                  </div>

                  <div class="col-auto">
                    <b>Итоговая оценка: 3,5</b>
                  </div>
                </div>

                <div class="row mt-24">
                  <div class="col">
                    <p class="text-size-12 text-color-text-secondary mb-8">Комментарий</p>
                    <p class="text-size-14">
                      Сотрудник проявил себя с лучшей стороны. Постоянно предлагались современные эффективные решения,
                      оперативно устранялись недочеты.
                    </p>
                  </div>
                </div>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <div class="content-fluid">
              <div class="row mt-8">
                <div class="col">
                  <s-nlmk-competency-assessment-tab2-table
                    v-for="i of 3"
                    :key="i"
                    class="mb-16"
                  />
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        </div>
      </template>
    </v-expansion-panels>
  </v-tab>
</template>

<script>
import {
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VIconSvg,
  VTab
} from '@components/base'
import SvgIconChevron from '@components/icons/SvgIconChevron'
import SNlmkCompetencyAssessmentTab2Table
  from '@views/Guide/Screen/SNlmkCompetencyAssessment/SNlmkCompetencyAssessmentTab2Table'

export default {
  name: 'SNlmkCompetencyAssessmentTab2',

  components: {
    SNlmkCompetencyAssessmentTab2Table,
    SvgIconChevron,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VExpansionPanels,
    VIconSvg,
    VTab
  },

  props: {
    tab: Object
  }
}
</script>
