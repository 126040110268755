<template>
  <div>
    <v-table with-span class="text-size-12">
      <template #thead>
        <v-table-tr>
          <v-table-th colspan="4" class="bg-color-primary text-color-light text-left">
            Активно взаимодействует с сотрудниками разных подразделений с целью обмена информацией по совместным
            задачам
          </v-table-th>
        </v-table-tr>
      </template>

      <template #default>
        <v-table-tr class="bg-color-background-7 text-bold">
          <v-table-td class="w-50">
            Вопросы для оценки
          </v-table-td>

          <v-table-td colspan="3">
            Ваша оценка
          </v-table-td>
        </v-table-tr>

        <v-table-tr>
          <v-table-td rowspan="6" class="p-0">
            <div class="p-12">
              <ul class="ul ul_type_point">
                <li>Приведите пример ситуации, когда Вы решали совместные задачи с коллегами из смежных подразделений.
                </li>
                <li>Какая задача перед вами стояла?</li>
                <li class="text-color-default">Дополнительный вопрос на «
                  <v-avatar color="success" size="16" class="text-size-16 text-bold mr-4">+</v-avatar>
                  <v-avatar color="success" size="16" class="text-size-16 text-bold">+</v-avatar>
                  »: Приведите пример ситуации, когда Вы выступали в роли организатора при
                  обсуждении совместных вопросов с коллегами? Что это была за ситуация? Какая цель стояла на это
                  обсуждение?
                  Что именно Вы делали для организации этого процесса? К какому результату пришли в итоге?
                </li>
              </ul>
            </div>

            <div
              class="bg-color-background-7 p-12 border-top border-bottom text-bold"
            >
              Ответы кандидата
            </div>

            <div class="p-12">
              <v-field size="12">
                <v-textarea
                  placeholder="Введите ответ кандидата"
                  autogrow
                />
              </v-field>
            </div>
          </v-table-td>
        </v-table-tr>

        <v-table-tr
          hovered
          :active="currentVariantId === variants[0].id"
          @click="currentVariantId = variants[0].id"
          class="cursor-pointer user-select-none"
        >
          <v-table-td class="text-color-default">
            В дополнение к «
            <v-avatar color="success" size="16" class="text-size-16 text-bold">+</v-avatar>
            »: Целенаправленно выявляет недостатки в процессах всего подразделения.
          </v-table-td>

          <v-table-td class="text-center text-size-16 text-bold" style="min-width: 6rem">
            <v-avatar color="success" size="16" class="mr-4">+</v-avatar>
            <v-avatar color="success" size="16">+</v-avatar>
          </v-table-td>

          <v-table-td>
            <v-radio v-model="currentVariantId" :value="variants[0].id"/>
          </v-table-td>
        </v-table-tr>

        <v-table-tr
          hovered
          :active="currentVariantId === variants[1].id"
          @click="currentVariantId = variants[1].id"
          class="cursor-pointer user-select-none"
        >
          <v-table-td>
            Активно обменивается информацией с коллегами, участвует в обсуждении всех затрагиваемых вопросов. Честно,
            без искажений озвучивает информацию.
          </v-table-td>

          <v-table-td class="text-center text-size-16 text-bold" style="min-width: 6rem">
            <v-avatar color="success" size="16">+</v-avatar>
          </v-table-td>

          <v-table-td>
            <v-radio v-model="currentVariantId" :value="variants[1].id"/>
          </v-table-td>
        </v-table-tr>

        <v-table-tr
          hovered
          :active="currentVariantId === variants[2].id"
          @click="currentVariantId = variants[2].id"
          class="cursor-pointer user-select-none"
        >
          <v-table-td>
            Не в полном объеме проявляет поведение, соответствующее уровню «
            <v-avatar class="text-size-16 text-bold" color="success" size="16">+</v-avatar>
            ».
          </v-table-td>

          <v-table-td class="text-center text-size-16 text-bold" style="min-width: 6rem">
            <v-avatar color="success" size="16" class="mr-4">+</v-avatar>
            <v-avatar color="danger" size="16">−</v-avatar>
          </v-table-td>

          <v-table-td>
            <v-radio v-model="currentVariantId" :value="variants[2].id"/>
          </v-table-td>
        </v-table-tr>

        <v-table-tr
          hovered
          :active="currentVariantId === variants[3].id"
          @click="currentVariantId = variants[3].id"
          class="cursor-pointer user-select-none"
        >
          <v-table-td>
            Не участвует в решении совместных задач.
          </v-table-td>

          <v-table-td class="text-center text-size-16 text-bold" style="min-width: 6rem">
            <v-avatar color="danger" size="16">−</v-avatar>
          </v-table-td>

          <v-table-td>
            <v-radio v-model="currentVariantId" :value="variants[3].id"/>
          </v-table-td>
        </v-table-tr>

        <v-table-tr
          hovered
          :active="currentVariantId === variants[4].id"
          @click="currentVariantId = variants[4].id"
          class="cursor-pointer user-select-none"
        >
          <v-table-td>
            Недостаточно данных для оценки
          </v-table-td>

          <v-table-td class="text-center text-size-16 text-bold" style="min-width: 6rem">
            <v-avatar color="default" size="16" class="mr-4">
              <b class="text-size-8">N</b>
            </v-avatar>
          </v-table-td>

          <v-table-td>
            <v-radio v-model="currentVariantId" :value="variants[4].id"/>
          </v-table-td>
        </v-table-tr>
      </template>
    </v-table>
  </div>
</template>

<script>
import {
  VAvatar,
  VField,
  VRadio,
  VTable,
  VTableTd,
  VTableTh,
  VTableTr,
  VTextarea
} from '@components/base'
import { createCustomArray } from '@utils/helpers'

export default {
  name: 'SNlmkCompetencyAssessmentTab3Table',

  components: {
    VAvatar,
    VField,
    VRadio,
    VTable,
    VTableTd,
    VTableTh,
    VTableTr,
    VTextarea
  },

  props: {
    anchors: Array
  },

  data () {
    return {
      currentVariantId: null,
      variants: createCustomArray({ length: 5, template: i => ({ id: i }) })
    }
  }
}
</script>
